(function ($) {
  Drupal.behaviors.gnavPromoBannerV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $module = $('.js-gnav-promo-banner-v1', context);

      $module.each(function () {
        var $carousel = $('.js-gnav-promo-banner-carousel', $module);
        var $slides = $carousel.find('.gnav-promo-banner__carousel-item');
        var $arrow = $('.js-gnav-promo-banner-carousel-arrow', $carousel);
        var selectedSlide = 1;
        var timer = null;
        var autoplay = $carousel.data('slides-autoplay');
        var speed = parseInt($carousel.data('speed'));

        function getNextSlide(currentSlide, isSlidingRight, slidesLength) {
          const loopingRight = isSlidingRight && currentSlide === slidesLength;
          const loopingLeft = !isSlidingRight && currentSlide === 1;

          if (loopingRight) {
            return 1;
          }

          if (loopingLeft) {
            return slidesLength;
          }

          return isSlidingRight ? currentSlide + 1 : currentSlide - 1;
        }

        function setCurrentSlide(newSlide) {
          selectedSlide = newSlide;
        }

        function showSlide(slide) {
          const slideIndex = slide - 1;

          $slides.removeClass('item-active');
          $slides.eq(slideIndex).addClass('item-active');
          setCurrentSlide(slide);

          if (autoplay) {
            const nextSlide = getNextSlide(selectedSlide, true, $slides.length);

            timer = setTimeout(() => showSlide(nextSlide), speed);
          }
        }

        function initSlider() {
          $arrow.once().on('click', function () {
            const isSlidingRight = parseInt($(this).data('index') || '1', 10) > 0;
            const nextSlide = getNextSlide(selectedSlide, isSlidingRight, $slides.length);

            clearTimeout(timer);
            showSlide(nextSlide);
          });

          $carousel.removeClass('not-initialized');
          showSlide(selectedSlide);
        }

        initSlider();
      });
    }
  };
})(jQuery);
